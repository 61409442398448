// Capitalize first letter of every word - address, city, names field
function capitalizeWord(str) {
  // return str
  //   .toLowerCase()
  //   .split(' ')
  //   .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //   .join(' ');

  // const lower = str.toLowerCase();
  // return str.charAt(0).toUpperCase() + lower.slice(1);

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default capitalizeWord;
