import { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Grid, useTheme } from '@mui/material';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { tokens } from '../../theme';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Header from '../../components/Header';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { token, serverURL, config } from '../../authentication/config';
import Modal from '@mui/material/Modal';
import FormButton from '../../components/Forms/FormButton';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Member = () => {
  const [members, setMembers] = useState([]);
  const [memberDetail, setMemberDetail] = useState(null);
  const [open, setOpen] = useState(false);

  // Fetch member details from server
  async function getMemberDetails(id) {
    try {
      const results = await axios(`${serverURL}/member/${id}/address`, config);
      setMemberDetail(results.data);
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Resource could not be found!');
      } else {
        console.log(error.message);
      }
    }
  }

  // Open the modal
  const handleOpen = (memberId) => {
    getMemberDetails(memberId);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Set page title
    document.title = 'Member List | Shree Swaminarayan Gadi Parivar';

    async function getMembers() {
      try {
        await axios(`${serverURL}/members`, config).then((response) => {
          setMembers(response.data);
        });
      } catch (error) {
        if (error.response.status === 404) {
          console.log('Resource could not be found!');
        } else {
          console.log(error.message);
        }
      }
    }
    getMembers();
  }, []);

  // Set search fields
  const searchParams = [
    'first_name',
    'middle_name',
    'last_name',
    'email',
    'mobile_phone',
  ];
  const addressSearch = ['address_line1', 'city'];

  const [query, setQuery] = useState('');
  const searchQuery = (data) => {
    return data.filter(
      (member) =>
        searchParams.some((searchParam) =>
          member[searchParam].toLowerCase().includes(query.toLowerCase())
        ) ||
        addressSearch.some((searchParam) =>
          member['address'][searchParam]
            .toLowerCase()
            .includes(query.toLowerCase())
        )
    );
  };

  // Set page theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Prepare DataGrid columns
  const columns = [
    {
      field: 'edit',
      headerName: 'Edit',
      width: 30,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Link to={`/familymembers/${params.row.id}`}>
          <EditIcon
            onClick={(event) => {
              event.ignore = true;
            }}
            sx={{
              color: colors.grey[100],
              '&:hover': { color: colors.blueAccent[300] },
              cursor: 'pointer',
            }}
          ></EditIcon>
        </Link>
      ),
    },
    {
      field: 'view',
      headerName: 'View',
      width: 30,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <VisibilityIcon
          onClick={() => handleOpen(params.row.id)}
          sx={{
            color: colors.grey[100],
            '&:hover': { color: colors.blueAccent[300] },
            cursor: 'pointer',
          }}
        ></VisibilityIcon>
      ),
    },
    {
      field: 'first_name',
      headerName: 'First name',
      width: 155,
    },
    {
      field: 'middle_name',
      headerName: 'Middle name',
      width: 155,
    },
    { field: 'last_name', headerName: 'Last name', width: 155 },
    { field: 'ghaam', headerName: 'Ghaam', width: 150 },
    {
      field: 'address',
      headerName: 'Address',
      width: 200,
      valueGetter: (params) => params.row.address.address_line1,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
      valueGetter: (params) => params.row.address.city,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 100,
      valueGetter: (params) => params.row.address.state.name,
    },
    {
      field: 'mandal',
      headerName: 'Mandal',
      width: 100,
      valueGetter: (params) => params.row.mandal.name,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      hide: true,
    },
    {
      field: 'mobile_phone',
      headerName: 'Mobile Phone',
      width: 125,
      hide: true,
    },
    {
      field: 'sabhya',
      headerName: 'Sabhya',
      type: 'boolean',
      width: 65,
      editable: true,
      hide: true,
    },
    {
      field: 'head_of_household',
      headerName: 'HoH',
      type: 'boolean',
      width: 65,
      editable: true,
      hide: true,
    },
    {
      field: 'satsangi',
      headerName: 'Satsangi',
      type: 'boolean',
      width: 65,
      editable: true,
      hide: true,
    },

    //   {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) =>
    //       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    //   },
  ];

  let modalJSX = '';
  if (memberDetail !== null) {
    modalJSX = (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ mb: 3 }}>
            <Typography
              display="inline"
              variant="h2"
              component="h2"
              sx={{ mb: 2 }}
            >
              {memberDetail.first_name +
                ' ' +
                memberDetail.middle_name +
                ' ' +
                memberDetail.last_name}
            </Typography>
            <Typography display="inline" variant="h5">
              {memberDetail.ghaam
                ? ' (' + memberDetail.ghaam + ') '
                : ' (Ghaam: N/A) '}
            </Typography>
          </Box>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={7}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  Address
                </Typography>

                <Typography variant="h5">
                  {memberDetail.address.address_line1}
                  {memberDetail.address.address_line2
                    ? ' - ' + memberDetail.address.address_line2
                    : ''}
                  <br />
                  {memberDetail.address.city +
                    ', ' +
                    memberDetail.address.state.name +
                    ' ' +
                    memberDetail.address.zipcode}
                </Typography>
              </Box>
              <Box>
                <Typography
                  display="inline"
                  variant="body1"
                  sx={{ color: 'text.secondary' }}
                >
                  Email:{' '}
                </Typography>
                <Typography display="inline" variant="h5">
                  {memberDetail.email ? memberDetail.email : 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography
                  display="inline"
                  variant="body1"
                  sx={{ color: 'text.secondary' }}
                >
                  Mobile:{' '}
                </Typography>
                <Typography display="inline" variant="h5">
                  {memberDetail.mobile_phone
                    ? memberDetail.mobile_phone
                    : 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography
                  display="inline"
                  variant="body1"
                  sx={{ color: 'text.secondary' }}
                >
                  Home:{' '}
                </Typography>
                <Typography display="inline" variant="h5">
                  {memberDetail.home_phone ? memberDetail.home_phone : 'N/A'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box sx={{ mb: 3 }}>
                <Box>
                  <Typography
                    display="inline"
                    variant="body1"
                    sx={{ color: 'text.secondary' }}
                  >
                    Mandal:{' '}
                  </Typography>
                  <Typography display="inline" variant="h5">
                    {memberDetail.mandal.name
                      ? memberDetail.mandal.name
                      : 'N/A'}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    display="inline"
                    variant="body1"
                    sx={{ color: 'text.secondary' }}
                  >
                    Gender:{' '}
                  </Typography>
                  <Typography display="inline" variant="h5">
                    {memberDetail.gender ? memberDetail.gender : 'N/A'}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    display="inline"
                    variant="body1"
                    sx={{ color: 'text.secondary' }}
                  >
                    Birth Year:{' '}
                  </Typography>
                  <Typography display="inline" variant="h5">
                    {memberDetail.dob
                      ? moment(memberDetail.dob).format('YYYY')
                      : 'N/A'}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    display="inline"
                    variant="body1"
                    sx={{ color: 'text.secondary' }}
                  >
                    GVijay ID:{' '}
                  </Typography>
                  <Typography display="inline" variant="h5">
                    {memberDetail.gvijay_id ? memberDetail.gvijay_id : 'N/A'}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box>
                  <Typography
                    display="inline"
                    variant="body1"
                    sx={{ color: 'text.secondary' }}
                  >
                    Satsangi:{' '}
                  </Typography>
                  <Typography display="inline" variant="h5">
                    {memberDetail.satsangi ? 'Yes' : 'No'}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    display="inline"
                    variant="body1"
                    sx={{ color: 'text.secondary' }}
                  >
                    Sabhya:{' '}
                  </Typography>
                  <Typography display="inline" variant="h5">
                    {memberDetail.sabhya ? 'Yes' : 'No'}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    display="inline"
                    variant="body1"
                    sx={{ color: 'text.secondary' }}
                  >
                    Head of Household:{' '}
                  </Typography>
                  <Typography display="inline" variant="h5">
                    {memberDetail.head_of_household ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Link to={`/member/edit/${memberDetail.id}`}>
            <FormButton
              text={'Edit Member'}
              bgColor={colors.blueAccent[700]}
              textColor={colors.grey[100]}
              type={'button'}
            />
          </Link>
        </Box>
      </Modal>
    );
  }

  return (
    <>
      <Box m="0 20px">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Header title="Members" subtitle="Members of your mandal" />
          <Box mt="5px">
            <Link to={`/familymembers/add/240`}>
              <FormButton
                text={'Add Family'}
                bgColor={colors.blueAccent[700]}
                textColor={colors.grey[100]}
                type={'button'}
              />
            </Link>
          </Box>
        </Box>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
              fontSize: '14px',
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300],
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.blueAccent[700],
              borderBottom: 'none',
              fontSize: '14px',
              fontWeight: '700',
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.blueAccent[700],
            },
            '& .MuiCheckbox-root': {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          {/* SEARCH BAR */}
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
          >
            <InputBase
              sx={{ ml: 2, flex: 1 }}
              placeholder="Search by first name, middle name, last name, email, phone number, address and city"
              onChange={(event) => setQuery(event.target.value)}
            />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
          </Box>

          {modalJSX}

          <DataGrid
            rows={searchQuery(members)}
            columns={columns}
            onRowClick={(params, event) => {
              if (!event.ignore) {
                <Link
                  to={{
                    pathname: '/member/' + params.row.uuid,
                  }}
                />;
              }
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Member;
