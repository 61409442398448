import { useRef, useState, useEffect } from 'react';
import { Box, Grid, Avatar, TextField, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { Formik, Form } from 'formik';
import FormButton from '../../components/Forms/FormButton';
import Snackbar from '@mui/material/Snackbar';
import * as Yup from 'yup';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from 'axios';
import { serverURL } from '../../authentication/config';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const debug = false;

function Login() {
  const navigate = useNavigate();

  // Set page theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [notification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  // Close notification
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification(false);
  };

  const paperStyle = {
    padding: 20,
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 auto',
    backgroundColor: colors.blueAccent[800],
  };
  const avatarStyle = { backgroundColor: colors.greenAccent[500] };
  const initialValues = {
    username: '',
    password: '',
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });
  const onSubmit = async (values, props) => {
    debug && console.log({ values: values, props: props });
    try {
     await axios({
        method: 'POST',
        url: `${serverURL}/login`,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json;charset=UTF-8',
        },
        data: values,
      })
        .then((response) => {
          props.resetForm();
          sessionStorage.setItem('token', response.data.accessToken);
          !debug && navigate('/members',{ replace: true });
        })
        .catch((error) => {
          debug && console.log(error?.response?.status);
          setNotificationMessage(error?.response?.data);
          setNotification(true);
        });
    } catch (error) {
      if (error.response.status === 404) {
        props.resetForm();
        console.log('Resource could not be found!');
      } else {
        props.resetForm();
        console.log(error.message);
      }
      // errRef.current.focus();
    }
  };

  return (
    <Box style={paperStyle}>
      <Grid align="center">
        <Avatar style={avatarStyle}>
          <LockOutlinedIcon />
        </Avatar>
        <h3>Shree Swaminarayan Gadi Parivar - North America</h3>
      </Grid>
      <Formik
        enableReinitialize={true}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          props,
        }) => (
          <Form onSubmit={handleSubmit}>
            <TextField
              variant="filled"
              label="Username"
              name="username"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              error={!!touched.username && !!errors.username}
              helperText={touched.username && errors.username}
              sx={{ mb: 2 }}
            />
            <TextField
              variant="filled"
              label="Password"
              name="password"
              placeholder="Enter password"
              type="password"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              error={!!touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: 'span 1' }}
            />
            <Box display="flex" justifyContent="end" mt="20px">
              <FormButton
                text={'Sign In'}
                bgColor={colors.greenAccent[300]}
                textColor={colors.grey[800]}
                type={'submit'}
              />
            </Box>

            {debug && (
              <>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Touched</strong>
                  <br />
                  {JSON.stringify(touched, null, 2)}
                </pre>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{notificationMessage}</span>}
      />
    </Box>
  );
}

export default Login;
