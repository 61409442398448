import {
  Box,
  Grid,
  Button,
  TextField,
  Radio,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { tokens } from '../../theme';
import { Form, Formik, FieldArray, getIn } from 'formik';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import 'yup-phone';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { serverURL, authToken } from '../../authentication/config';
import FormButton from '../../components/Forms/FormButton';
import { MuiTelInput } from 'mui-tel-input';
import { dobModified } from '../../components/Forms/Member/MemberValidation';

const debug = false;

const { format } = require('date-fns');
const dateToday = format(new Date(), 'yyyy-MM-dd');

const validationSchema = Yup.object().shape({
  members: Yup.array(
    Yup.object().shape(
      {
        first_name: Yup.string().required('First name is required'),
        middle_name: Yup.string().min(2, 'Please enter full middle name'),
        last_name: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email').nullable(),
        gender: Yup.string().required('Gender is required'),
        ghaam: Yup.string().required('Ghaam is required'),
        mobile_phone: Yup.string().nullable(),
        gvijay_id: Yup.number().nullable(),
        gvijay_status: Yup.string().nullable(),
        source: Yup.string().nullable(),
        satsangi: Yup.boolean(),
        sabhya: Yup.boolean(),
        head_of_household: Yup.boolean(),
        notes: Yup.string().nullable(),

        dob: Yup.string()
          .ensure()
          .when('birth_year', {
            is: '',
            then: Yup.string().required(
              'Either birth year or birth date is required'
            ),
          }),
        birth_year: Yup.string()
          .ensure()
          .when('dob', {
            is: '',
            then: Yup.string().required(
              'Either birth year or birth date is required'
            ),
          }),
      },
      ['birth_year', 'dob']
    )
  ),
});

const currentYear = new Date().getFullYear();
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
const birthYears = range(currentYear, currentYear - 100, -1);

// Capitalize first letter of every word - address, city, names field
const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

function AddMember() {
  // Set page theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const urlParams = useParams();
  const [notification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [value, setValue] = useState();

  const initialMemberValues = {
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    gender: '',
    ghaam: '',
    mobile_phone: '',
    home_phone: '',
    dob: dateToday,
    birth_year: '',
    address_id: Number(urlParams.addressId),
    mandal_id: Number(urlParams.mandalId),
    gvijay_id: null,
    gvijay_status: '',
    source: '',
    satsangi: false,
    sabhya: false,
    head_of_household: false,
    notes: '',
  };

  const initialValues = {
    members: [initialMemberValues],
  };

  useEffect(() => {
    // Set page title
    document.title = 'Add Family Members | Shree Swaminarayan Gadi Parivar';
  }, []);

  // Close notification
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification(false);
  };

  const isNonMobile = useMediaQuery('(min-width:600px)');

  // Post form data
  async function postData(data) {
    debug && console.log(JSON.stringify(data));
    try {
      const newMemberArray = [];
      data.members.forEach((member) => {
        newMemberArray.push({
          ...member,
          dob: dobModified(member.dob, member.birth_year),
        });
      });

      newMemberArray.forEach((member) => {
        delete member.birth_year;
      });

      // Post members array
      axios({
        method: 'POST',
        url: `${serverURL}/members/`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${authToken}`,
        },
        data: newMemberArray,
      }).then((response) => {
        if (response.status === 200) {
          setNotificationMessage('Family members successfully submitted!');
          setNotification(true);
          setTimeout(() => {
            !debug && navigate(`/familymembers/${urlParams.memberId}`);
          }, 1500);
        }
      });
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Resource could not be found!');
      } else {
        console.log(error.message);
      }
    }
  }

  const handleFormSubmit = (event) => {
    postData(event);
  };

  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ];

  return (
    <Box m="0 20px">
      <Header title="Add Members" subtitle={`Add family members`} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isValid,
        }) => (
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <FieldArray name="members">
              {({ push, remove }) => (
                <>
                  {values.members.map((member, index) => {
                    const first_name = `members[${index}].first_name`;
                    const touchedFirstName = getIn(touched, first_name);
                    const errorFirstName = getIn(errors, first_name);

                    const middle_name = `members[${index}].middle_name`;
                    const touchedMiddleName = getIn(touched, middle_name);
                    const errorMiddleName = getIn(errors, middle_name);

                    const last_name = `members[${index}].last_name`;
                    const touchedLastName = getIn(touched, last_name);
                    const errorLastName = getIn(errors, last_name);

                    const ghaam = `members[${index}].ghaam`;
                    const touchedGhaam = getIn(touched, ghaam);
                    const errorGhaam = getIn(errors, ghaam);

                    const dob = `members[${index}].dob`;
                    const touchedDOB = getIn(touched, dob);
                    const errorDOB = getIn(errors, dob);

                    const birth_year = `members[${index}].birth_year`;
                    const touchedBirthYear = getIn(touched, birth_year);
                    const errorBirthYear = getIn(errors, birth_year);

                    const email = `members[${index}].email`;
                    const touchedEmail = getIn(touched, email);
                    const errorEmail = getIn(errors, email);

                    const mobile_phone = `members[${index}].mobile_phone`;
                    const touchedMobile = getIn(touched, mobile_phone);
                    const errorMobile = getIn(errors, mobile_phone);

                    const home_phone = `members[${index}].home_phone`;
                    const touchedHome = getIn(touched, home_phone);
                    const errorHome = getIn(errors, home_phone);

                    const gender = `members[${index}].gender`;
                    const touchedGender = getIn(touched, gender);
                    const errorGender = getIn(errors, gender);

                    const satsangi = `members[${index}].satsangi`;
                    const touchedSatsangi = getIn(touched, satsangi);
                    const errorSatsangi = getIn(errors, satsangi);

                    const sabhya = `members[${index}].sabhya`;
                    const touchedSabhya = getIn(touched, sabhya);
                    const errorSabhya = getIn(errors, sabhya);

                    const head_of_household = `members[${index}].head_of_household`;
                    const touchedHOH = getIn(touched, head_of_household);
                    const errorHOH = getIn(errors, head_of_household);

                    const notes = `members[${index}].notes`;
                    const touchedNotes = getIn(touched, notes);
                    const errorNotes = getIn(errors, notes);

                    return (
                      <Box key={index}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <h4>Member {index + 1}</h4>
                          <Box mt="5px">
                            <Button
                              style={{
                                backgroundColor: colors.redAccent[700],
                                color: colors.grey[100],
                              }}
                              variant="outlined"
                              onClick={() => remove(index)}
                            >
                              Remove Member
                            </Button>
                          </Box>
                        </Box>
                        <Grid
                          display="grid"
                          gap="30px"
                          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                          sx={{
                            '& > div': {
                              gridColumn: isNonMobile ? undefined : 'span 4',
                            },
                          }}
                        >
                          <TextField
                            variant="filled"
                            name={`members.${index}.first_name`}
                            label="First Name"
                            value={capitalizeWords(
                              values.members[index].first_name
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!touchedFirstName && !!errorFirstName}
                            helperText={touchedFirstName && errorFirstName}
                            sx={{ gridColumn: 'span 1' }}
                          />

                          <TextField
                            variant="filled"
                            name={`members.${index}.middle_name`}
                            label="Middle Name"
                            value={capitalizeWords(
                              values.members[index].middle_name
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!touchedMiddleName && !!errorMiddleName}
                            helperText={touchedMiddleName && errorMiddleName}
                            sx={{ gridColumn: 'span 1' }}
                          />
                          <TextField
                            variant="filled"
                            name={`members.${index}.last_name`}
                            label="Last Name"
                            value={capitalizeWords(
                              values.members[index].last_name
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!touchedLastName && !!errorLastName}
                            helperText={touchedLastName && errorLastName}
                            sx={{ gridColumn: 'span 1' }}
                          />
                          <TextField
                            variant="filled"
                            name={`members.${index}.ghaam`}
                            label="Ghaam"
                            value={capitalizeWords(values.members[index].ghaam)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!touchedGhaam && !!errorGhaam}
                            helperText={touchedGhaam && errorGhaam}
                            sx={{ gridColumn: 'span 1' }}
                          />
                          <TextField
                            variant="filled"
                            name={`members.${index}.email`}
                            label="Email"
                            value={values.members[index].email.toLowerCase()}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!touchedEmail && !!errorEmail}
                            helperText={touchedEmail && errorEmail}
                            sx={{ gridColumn: 'span 1' }}
                          />
                          <TextField
                            variant="filled"
                            type="date"
                            label="Date of Birth"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.members[index].dob}
                            name={`members.${index}.dob`}
                            error={!!touchedDOB && !!errorDOB}
                            helperText={touchedDOB && errorDOB}
                            sx={{ gridColumn: 'span 1' }}
                          />
                          <TextField
                            select
                            variant="filled"
                            type="text"
                            label="Birth Year"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.members[index].birth_year}
                            name={`members.${index}.birth_year`}
                            error={!!touchedBirthYear && !!errorBirthYear}
                            helperText={touchedBirthYear && errorBirthYear}
                            sx={{ gridColumn: 'span 1' }}
                          >
                            {birthYears.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </TextField>
                          <MuiTelInput
                            variant="filled"
                            name={`members.${index}.mobile_phone`}
                            label="Mobile Phone"
                            value={values.members[index].mobile_phone}
                            onBlur={handleBlur}
                            onChange={(ev) => {
                              setValue(ev);
                              setFieldValue(
                                `members[${index}].mobile_phone`,
                                ev
                              );
                            }}
                            error={!!touchedMobile && !!errorMobile}
                            helperText={touchedMobile && errorMobile}
                            sx={{ gridColumn: 'span 1' }}
                          />
                          {/* <TextField
                            variant="filled"
                            name={`members.${index}.home_phone`}
                            label="Home Phone"
                            value={values.members[index].home_phone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!touchedHome && !!errorHome}
                            helperText={touchedHome && errorHome}
                            sx={{ gridColumn: 'span 1' }}
                          /> */}

                          <FormControl component="fieldset">
                            <FormLabel
                              component="legend"
                              error={!!touchedGender && !!errorGender}
                            >
                              Gender
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-label="position"
                              name={`members.${index}.gender`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.members[index].gender}
                              sx={{ gridColumn: 'span 1' }}
                            >
                              {genderOptions.map((option) => (
                                <FormControlLabel
                                  variant="filled"
                                  type="radio"
                                  value={option.value}
                                  key={option.value}
                                  checked={member.gender === option.value}
                                  label={option.label}
                                  id="gender"
                                  control={
                                    <Radio
                                      sx={{
                                        '&.Mui-checked': {
                                          color: 'rgba(255, 255, 255, 0.7);',
                                        },
                                      }}
                                    />
                                  }
                                />
                              ))}
                            </RadioGroup>
                            {touchedGender && Boolean(errorGender) ? (
                              <Box
                                className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1ksiht6-MuiFormHelperText-root"
                                severity="error"
                              >
                                Please choose a gender
                              </Box>
                            ) : (
                              ''
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel
                              error={!!touchedSatsangi && !!errorSatsangi}
                            >
                              Is Satsangi
                            </FormLabel>
                            <FormControlLabel
                              control={<Checkbox checked={member.satsangi} />}
                              label="Yes"
                              name={`members.${index}.satsangi`}
                              onChange={handleChange}
                              sx={{
                                gridColumn: 'span 1',
                                '& .MuiSvgIcon-root': {
                                  fill: 'rgba(255, 255, 255, 0.7);',
                                },
                              }}
                            />
                            {touchedSatsangi && Boolean(errorSatsangi) ? (
                              <Box
                                className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1ksiht6-MuiFormHelperText-root"
                                severity="error"
                              >
                                ('')
                              </Box>
                            ) : (
                              ''
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel error={!!touchedSabhya && !!errorSabhya}>
                              Is Sabhya
                            </FormLabel>
                            <FormControlLabel
                              control={<Checkbox checked={member.sabhya} />}
                              label="Yes"
                              name={`members.${index}.sabhya`}
                              onChange={handleChange}
                              sx={{
                                gridColumn: 'span 1',
                                '& .MuiSvgIcon-root': {
                                  fill: 'rgba(255, 255, 255, 0.7);',
                                },
                              }}
                            />
                            {touchedSabhya && Boolean(errorSabhya) ? (
                              <Box
                                className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1ksiht6-MuiFormHelperText-root"
                                severity="error"
                              >
                                ('')
                              </Box>
                            ) : (
                              ''
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel error={!!touchedHOH && !!errorHOH}>
                              Is Head of Household
                            </FormLabel>
                            <FormControlLabel
                              control={
                                <Checkbox checked={member.head_of_household} />
                              }
                              label="Yes"
                              name={`members.${index}.head_of_household`}
                              onChange={handleChange}
                              sx={{
                                gridColumn: 'span 1',
                                '& .MuiSvgIcon-root': {
                                  fill: 'rgba(255, 255, 255, 0.7);',
                                },
                              }}
                            />
                            {touchedHOH && Boolean(errorHOH) ? (
                              <Box
                                className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1ksiht6-MuiFormHelperText-root"
                                severity="error"
                              >
                                ('')
                              </Box>
                            ) : (
                              ''
                            )}
                          </FormControl>
                          <FormControl>
                            <TextField
                              variant="filled"
                              name={`members.${index}.notes`}
                              label="Notes"
                              value={values.members[index].notes}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              multiline // Enable multi-line input
                              rows={6} // Specify the number of rows displayed
                              error={!!touchedNotes && !!errorNotes}
                              helperText={touchedNotes && errorNotes}
                              sx={{ gridColumn: 'span 1' }}
                            />
                          </FormControl>
                        </Grid>
                      </Box>
                    );
                  })}
                  <Box mt="5px">
                    <Button
                      style={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                      }}
                      variant="outlined"
                      onClick={() => push(initialMemberValues)}
                    >
                      Add Member
                    </Button>
                  </Box>
                </>
              )}
            </FieldArray>

            <Box display="flex" justifyContent="end" mt="20px">
              <FormButton
                text={'Submit'}
                bgColor={colors.greenAccent[300]}
                textColor={colors.grey[800]}
                type={'submit'}
              />
            </Box>
            {debug && (
              <>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Touched</strong>
                  <br />
                  {JSON.stringify(touched, null, 2)}
                </pre>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{notificationMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseNotification}
          ></IconButton>,
        ]}
      />
    </Box>
  );
}

export default AddMember;
