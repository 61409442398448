import moment from 'moment';
import * as Yup from 'yup';
import 'yup-phone';
import { formatInTimeZone } from 'date-fns-tz';
const { format, parseISO, addDays } = require('date-fns');

const dateToday = format(new Date(), 'yyyy-MM-dd');
console.log(dateToday);

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const memberValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  middle_name: Yup.string().min(2, 'Please enter full middle name'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').nullable(),
  gender: Yup.string().required('Gender is required'),
  ghaam: Yup.string().required('Ghaam is required'),
  mobile_phone: Yup.string().nullable(),
  // home_phone: Yup.string()
  //   .matches(phoneRegExp, 'Phone number is not valid')
  //   .min(10, 'Please enter a valied phone number')
  //   .max(10, 'Please enter a valid phone number')
  //   .nullable(),
  // dob: Yup.date()
  //   .max(new Date(), 'Please enter a valied date of birth')
  //   .nullable(),
  gvijay_id: Yup.number().nullable(),
  gvijay_status: Yup.string().nullable(),
  source: Yup.string().nullable(),
  satsangi: Yup.boolean(),
  sabhya: Yup.boolean(),
  head_of_household: Yup.boolean(),
  notes: Yup.string().nullable(),
});

export const initialMemberValues = {
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  gender: '',
  ghaam: '',
  mobile_phone: '',
  home_phone: '',
  // dob: dateToday,
  // birth_year: parseInt(format(new Date(), 'yyyy'), 10),
  dob: null,
  birth_year: '',
  address_id: null,
  mandal_id: null,
  gvijay_id: null,
  gvijay_status: '',
  source: '',
  satsangi: false,
  sabhya: false,
  head_of_household: false,
  notes: '',
};

// Adds the birth year field to the member array
// If there is no dob then default birth year is current year otherwise it gets the dob birth year
export function birthYearModified(memberArray) {
  const dbDOB = memberArray.dob
    ? format(addDays(parseISO(memberArray.dob), 1), 'yyyy-MM-dd')
    : dateToday;
  // const birth_year = dbDOB.getFullYear();
  console.log(formatInTimeZone(dbDOB, 'America/New_York', 'yyyy-MM-dd'));
  const birth_year = format(parseISO(dbDOB), 'yyyy');
  console.log(birth_year);
  const birth_year_int = parseInt(birth_year, 10);
  const updatedMemberArray = {
    ...memberArray,
    birth_year: birth_year_int,
    dob: dbDOB,
  };
  return updatedMemberArray;
}

// If user has input birth year, modify dob to save the birth year
// Default dob will be 01/01/[birth_year]
export function dobModified(dob, birth_year) {
  // Set date today format for comparison with dob
  let dateToday = new Date();
  dateToday = format(dateToday, 'yyyy-MM-dd');
  console.log({ dateToday: dateToday });

  // Set this year format for comparison with birth_year
  const thisYear = format(parseISO(dateToday), 'yyyy');
  console.log({ thisYear: thisYear });

  let userDOB = dob;
  console.log(dob);
  console.log({ userDOB: userDOB });
  userDOB = format(parseISO(userDOB), 'yyyy-MM-dd');
  console.log({ userDOB_formatted: userDOB });

  const userBirthYear = birth_year.toString();
  console.log({ userBirthYear: userBirthYear.toString() });

  let newDOB = new Date(userDOB);

  // Logic
  // Check to see if user inputed dob (date other than today)
  if (userDOB !== dateToday) {
    // If user did input dob then save the user dob
    return newDOB;
  } else {
    // Else check to see if user inputed birth_year and save dob as 01/01/[birth_year]
    if (userBirthYear !== thisYear) {
      return new Date(
        format(newDOB.setFullYear(userBirthYear, 0, 1), 'yyyy-MM-dd')
      );
    } else {
      // If user did not input the birth_year then return null for dob
      return null;
    }
  }
}
