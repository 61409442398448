import { Box, Button, useTheme } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { tokens } from '../../theme';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { serverURL, authToken, config } from '../../authentication/config';
import MemberForm from '../../components/Forms/Member/MemberForm';
import {
  initialMemberValues,
  memberValidationSchema,
  birthYearModified,
  dobModified,
} from '../../components/Forms/Member/MemberValidation';

const debug = false;

function EditMember() {
  // Set page theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const urlParams = useParams();
  const [member, setMember] = useState(initialMemberValues);
  const [notification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  useEffect(() => {
    // Set page title
    document.title = 'Edit Member | Shree Swaminarayan Gadi Parivar';

    // Fetch member from server
    async function getMember() {
      try {
        const results = await axios(
          `${serverURL}/member/${urlParams.uuid}`,
          config
        );
        setMember(
          birthYearModified(results.data)
          // {
          // ...results.data,
          // dob:
          //   results.data.dob === null
          //     ? moment(Date.now()).format('YYYY-MM-DD')
          //     : moment(results.data.dob).format('YYYY-MM-DD'),
          // }
        );
      } catch (error) {
        if (error.response.status === 404) {
          console.log('Resource could not be found!');
        } else {
          console.log(error.message);
        }
      }
    }

    getMember();
  }, []);

  // Close notification
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification(false);
  };

  const isNonMobile = useMediaQuery('(min-width:600px)');

  // Post form data
  async function postData(data) {
    console.log(dobModified(data.dob, data.birth_year));
    try {
      axios({
        method: 'PUT',
        url: `${serverURL}/member/${urlParams.uuid}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: authToken,
        },
        data: { ...data, dob: dobModified(data.dob, data.birth_year) },
      }).then((response) => {
        if (response.status === 200) {
          setNotificationMessage('Member successfully updated!');
          setNotification(true);
          setTimeout(() => {
            !debug && navigate(`/familymembers/${urlParams.uuid}`);
          }, 1500);
        }
      });
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Resource could not be found!');
      } else {
        console.log(error.message);
      }
    }
  }

  const handleFormSubmit = (event) => {
    postData(event);
  };

  return (
    <Box m="0 20px">
      <Header title="Edit Member" />

      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={member}
        validationSchema={memberValidationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          setFieldValue,
        }) => (
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <MemberForm
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                values={values}
                touched={touched}
                setFieldValue={setFieldValue}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                style={{
                  backgroundColor: colors.greenAccent[300],
                  color: colors.grey[800],
                }}
                variant="outlined"
              >
                Submit
              </Button>
            </Box>
            {debug && (
              <>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Touched</strong>
                  <br />
                  {JSON.stringify(touched, null, 2)}
                </pre>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{notificationMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseNotification}
          ></IconButton>,
        ]}
      />
    </Box>
  );
}

export default EditMember;
