import { useState, useEffect } from 'react';
import axios from 'axios';
import { tokens } from '../../theme';
import { Box, Typography, useTheme } from '@mui/material';
import Header from '../../components/Header';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { serverURL, config } from '../../authentication/config';
import EditIcon from '@mui/icons-material/Edit';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { Link } from 'react-router-dom';
import FormButton from '../../components/Forms/FormButton';
import { useNavigate } from 'react-router-dom';

function FamilyMembers() {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [member, setMember] = useState(null);
  const [familyMembers, setFamilyMembers] = useState([]);
  async function getMember() {
    try {
      await axios(`${serverURL}/familymembers/${urlParams.uuid}`, config).then(
        (response) => {
          setFamilyMembers(response.data);

          // If family member query is successful then get the member detail
          // Family member query validates if user has permission otherwise redirects
          axios(`${serverURL}/member/${urlParams.uuid}/address`, config).then(
            (response) => {
              setMember(response.data);
            }
          );
        }
      );
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Resource could not be found!');
      } else {
        console.log(error.message);
        navigate('/members');
      }
    }
  }
  useEffect(() => {
    // Set page title
    document.title = 'Family Member | Shree Swaminarayan Gadi Parivar';
    getMember();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Prepare DataGrid columns
  const columns = [
    {
      field: 'id',
      headerName: 'Edit',
      width: 50,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Link to={`/member/edit/${params.row.id}`}>
          <EditIcon
            onClick={(event) => {
              event.ignore = true;
            }}
            sx={{
              color: colors.grey[100],
              '&:hover': { color: colors.blueAccent[300] },
              cursor: 'pointer',
            }}
          ></EditIcon>
        </Link>
      ),
    },
    {
      field: 'move',
      headerName: 'Move',
      width: 50,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Link to={`/address/attach/${params.row.id}`}>
          <PersonRemoveIcon
            onClick={(event) => {
              event.ignore = true;
            }}
            sx={{
              color: colors.grey[100],
              '&:hover': { color: colors.blueAccent[300] },
              cursor: 'pointer',
            }}
          ></PersonRemoveIcon>
        </Link>
      ),
    },
    {
      field: 'first_name',
      headerName: 'First name',
      width: 140,
      editable: false,
    },
    {
      field: 'middle_name',
      headerName: 'Middle name',
      width: 140,
      editable: false,
    },
    { field: 'last_name', headerName: 'Last name', width: 130 },
    { field: 'ghaam', headerName: 'Ghaam', width: 130 },
    {
      field: 'email',
      headerName: 'Email',
      width: 210,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      width: 75,
      hide: true,
    },
    {
      field: 'dob',
      headerName: 'DOB',
      width: 130,
      hide: true,
    },
    {
      field: 'mobile_phone',
      headerName: 'Mobile Phone',
      width: 130,
    },
    {
      field: 'sabhya',
      headerName: 'Sabhya',
      type: 'boolean',
      editable: false,
    },
    {
      field: 'head_of_household',
      headerName: 'Head of Household',
      type: 'boolean',
      editable: false,
    },
    {
      field: 'satsangi',
      headerName: 'Satsangi',
      type: 'boolean',
      editable: false,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 110,
      editable: false,
      hide: false,
    },

    //   {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) =>
    //       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    //   },
  ];

  if (member !== null) {
    const fullName =
      member.first_name +
      ' ' +
      (member.middle_name && member.middle_name + ' ') +
      member.last_name;
    const fullAddress =
      member.address.address_line1 +
      ', ' +
      (member.address.address_line2 && member.address.address_line2 + ', ') +
      member.address.city +
      ', ' +
      member.address.state.name +
      ' ' +
      member.address.zipcode;
    const mandal = 'Mandal: ' + member.mandal.name;
    return (
      <>
        <Box m="0 20px">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* <Header title={fullName} subtitle={fullAddress} /> */}
            <Box mb="1rem">
              <Typography
                variant="h2"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: '0 0 5px 0' }}
                textDecoration="none"
                boxShadow="none"
              >
                {fullName}
              </Typography>
              <a
                href={`https://google.com/maps/search/${
                  member.address.address_line1.replace(' ', '+') +
                  '+' +
                  (member.address.address_line2 &&
                    member.address.address_line2.replace(' ', '+') + '+') +
                  member.address.city.replace(' ', '+') +
                  '+' +
                  member.address.state.name +
                  '+' +
                  member.address.zipcode
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="h5" color={colors.greenAccent[400]}>
                  {fullAddress}
                </Typography>
              </a>
              <Typography
                variant="h5"
                color={colors.grey[100]}
                sx={{ m: '5px 0 5px 0' }}
                textDecoration="none"
                boxShadow="none"
              >
                {mandal}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Link
                to={`/member/add/${member.address_id}/${member.id}/${member.mandal_id}`}
              >
                <FormButton
                  text={'Add Member'}
                  bgColor={colors.blueAccent[700]}
                  textColor={colors.grey[100]}
                  type={'button'}
                />
              </Link>
              <Link to={`/address/edit/${member.address_id}/${member.id}`}>
                <FormButton
                  text={'Edit Address'}
                  bgColor={colors.greenAccent[300]}
                  textColor={colors.grey[800]}
                  type={'button'}
                />
              </Link>
            </Box>
          </Box>
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              '& .MuiDataGrid-root': {
                border: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: 'none',
                fontSize: '14px',
              },
              '& .name-column--cell': {
                color: colors.greenAccent[300],
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: colors.blueAccent[700],
                borderBottom: 'none',
                fontSize: '14px',
                fontWeight: '700',
              },
              '& .MuiDataGrid-virtualScroller': {
                backgroundColor: colors.primary[400],
              },
              '& .MuiDataGrid-footerContainer': {
                borderTop: 'none',
                backgroundColor: colors.blueAccent[700],
              },
              '& .MuiCheckbox-root': {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rows={familyMembers}
              columns={columns}
              onRowClick={(params, event) => {
                if (!event.ignore) {
                  <Link
                    to={{
                      pathname: '/member/' + params.row.uuid,
                    }}
                  />;
                }
              }}
            />
          </Box>
        </Box>
      </>
    );
  }
}

export default FamilyMembers;
