function Dashboard() {
  console.log('Dashboard');
  return (
    <>
      <div>Dashboard</div>
    </>
  );
}

export default Dashboard;
