import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

function FormButton({ text, bgColor, textColor, type }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <Button
        type={type}
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
        variant="outlined"
      >
        {text}
      </Button>
    </>
  );
}

export default FormButton;
