import { Box, IconButton, useTheme, Typography, Button } from '@mui/material';
import { useContext } from 'react';
import { ColorModeContext, tokens } from '../../theme';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { useNavigate, Link } from 'react-router-dom';

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    navigate('/login');
  };

  const token = sessionStorage.getItem('token');

  if (token) {
    return (
      <Box display="flex" justifyContent="space-between" p={2}>
        <Box>
          <Typography variant="body2">
            Shree Swaminarayan Gadi Parivar - North America
          </Typography>
        </Box>
        {/* ICONS */}
        <Box display="flex">
          <Link to={'/members'}>
            <Button
              type="button"
              style={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
              variant="outlined"
            >
              Member List
            </Button>
          </Link>
          {/* <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === 'dark' ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
          <IconButton>
            <NotificationsOutlinedIcon />
          </IconButton>
          <IconButton>
            <SettingsOutlinedIcon />
          </IconButton> */}
          <IconButton onClick={handleLogout}>
            <PersonOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
    );
  }
};

export default Topbar;
