const token = sessionStorage.getItem('token');
export const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    Authorization: 'Bearer ' + token || '',
  },
};
export const header = {
  Accept: 'application/json',
  'Content-Type': 'application/json;charset=UTF-8',
  Authorization: 'Bearer ' + token ||'',
};

export const authToken = 'Bearer ' + token ||'';

export const serverURL = process.env.REACT_APP_SERVER_HOST;
