import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import capitalizeWords from '../../capitalizeWord';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  address_line1: Yup.string().required('Address is required'),
  address_line2: Yup.string().nullable(),
  city: Yup.string().required('City is required'),
  state_id: Yup.number()
    .required('State is required')
    .min(1, 'Please select a State'),
  zipcode: Yup.string().required('Zip / Postal Code is required'),
  mandal_id: Yup.number()
    .required('Mandal is required')
    .min(1, 'Please select a Mandal'),
  notes: Yup.string().nullable(),
});

export const initialValues = {
  address_line1: '',
  address_line2: '',
  city: '',
  state_id: '',
  zipcode: '',
  mandal_id: '',
  notes: '',
};

function AddressForm({
  handleBlur,
  handleChange,
  errors,
  values,
  touched,
  states,
  mandals,
}) {
  return (
    <>
      <TextField
        variant="filled"
        type="text"
        label="Address line 1"
        onBlur={handleBlur}
        onChange={handleChange}
        value={capitalizeWords(values.address_line1)}
        name="address_line1"
        error={!!touched.address_line1 && !!errors.address_line1}
        helperText={touched.address_line1 && errors.address_line1}
        sx={{ gridColumn: 'span 4' }}
      />
      <TextField
        variant="filled"
        type="text"
        label="Address line 2"
        onBlur={handleBlur}
        onChange={handleChange}
        value={capitalizeWords(values.address_line2)}
        name="address_line2"
        error={!!touched.address_line2 && !!errors.address_line2}
        helperText={touched.address_line2 && errors.address_line2}
        sx={{ gridColumn: 'span 4' }}
      />
      <TextField
        variant="filled"
        type="text"
        label="City"
        onBlur={handleBlur}
        onChange={handleChange}
        value={capitalizeWords(values.city)}
        name="city"
        error={!!touched.city && !!errors.city}
        helperText={touched.city && errors.city}
        sx={{ gridColumn: 'span 1' }}
      />
      <TextField
        select
        variant="filled"
        type="text"
        label="State"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.state_id}
        name="state_id"
        error={!!touched.state_id && !!errors.state_id}
        helperText={touched.state_id && errors.state_id}
        sx={{ gridColumn: 'span 1' }}
      >
        {states.map((state) => (
          <MenuItem
            key={state.id}
            value={state.id}
            selected={state.id == values.state_id}
          >
            {state.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        variant="filled"
        type="text"
        label="Zip / Postal Code"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.zipcode}
        name="zipcode"
        error={!!touched.zipcode && !!errors.zipcode}
        helperText={touched.zipcode && errors.zipcode}
        sx={{ gridColumn: 'span 1' }}
      />
      <TextField
        select
        variant="filled"
        type="text"
        label="Mandal"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.mandal_id}
        name="mandal_id"
        error={!!touched.mandal_id && !!errors.mandal_id}
        helperText={touched.mandal_id && errors.mandal_id}
        sx={{ gridColumn: 'span 1' }}
      >
        {mandals.map((mandal) => (
          <MenuItem key={mandal.id} value={mandal.id}>
            {mandal.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        variant="filled"
        type="text"
        label="Notes"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.notes}
        multiline // Enable multi-line input
        rows={3} // Specify the number of rows displayed
        name="notes"
        error={!!touched.notes && !!errors.notes}
        helperText={touched.notes && errors.notes}
        sx={{ gridColumn: 'span 4' }}
      />
    </>
  );
}

export default AddressForm;
