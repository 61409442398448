import { Box, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { tokens } from '../../theme';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { serverURL, authToken, config } from '../../authentication/config';
import AddressForm from '../../components/Forms/Address/AddressForm';
import capitalizeWords from '../../components/capitalizeWord';
import FormButton from '../../components/Forms/FormButton';

const debug = false;

const validationSchema = Yup.object().shape({
  address_line1: Yup.string().required('Address is required'),
  address_line2: Yup.string().nullable(),
  city: Yup.string().required('City is required'),
  state_id: Yup.number()
    .required('State is required')
    .min(1, 'Please select a State'),
  zipcode: Yup.string().required('Zip / Postal Code is required'),
  country: Yup.number().min(0, 'Please select a Country'),
});

const initialValues = {
  address_line1: '',
  address_line2: '',
  city: '',
  state_id: '',
  zipcode: '',
  mandal_id: '',
};

function AttachAddress() {
  // Set page theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const urlParams = useParams();

  const [states, setStates] = useState([{ name: 'Select State', id: 3564 }]);
  const [mandals, setMandals] = useState([{ name: 'Select Mandal', id: 0 }]);
  const [notification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  // States for existing address
  const [addresses, setAddresses] = useState([]);
  // const [addressOptions, setAddressOptions] = useState({
  //   id: 0,
  //   address: 'Please select an address',
  // });
  const [value, setValue] = useState(addresses[0]);
  // const [address, setAddress] = useState('');

  useEffect(() => {
    // Set page title
    document.title = 'Attach New Address | Shree Swaminarayan Gadi Parivar';

    // Fetch addresses for select list
    async function getAddresses() {
      try {
        const results = await axios(`${serverURL}/addresses`, config);
        let addressArray = [];
        let addressOptionsArray = [];
        results.data.forEach((element) => {
          addressArray.push(
            element.address_line1 +
              ' ' +
              element.address_line2 +
              ' ' +
              element.city +
              ', ' +
              element.state.name
          );
          addressOptionsArray.push({
            id: element.id,
            address:
              element.address_line1 +
              ', ' +
              element.address_line2 +
              ', ' +
              element.city +
              ', ' +
              element.state.name,
          });
        });
        setAddresses(results.data);
        // setAddressOptions(addressOptionsArray);
      } catch (error) {
        if (error.response.status === 404) {
          console.log('Resource could not be found!');
        } else {
          console.log(error.message);
        }
      }
    }

    // Fetch states from server
    async function getStates() {
      try {
        const results = await axios(
          `${serverURL}/northamerica/states/`,
          config
        );
        setStates(results.data);
      } catch (error) {
        if (error.response.status === 404) {
          console.log('Resource could not be found!');
        } else {
          console.log(error.message);
        }
      }
    }

    // Fetch country from server
    async function getMandals() {
      try {
        const results = await axios(`${serverURL}/mandals/`, config);
        setMandals(results.data);
      } catch (error) {
        if (error.response.status === 404) {
          console.log('Resource could not be found!');
        } else {
          console.log(error.message);
        }
      }
    }

    getAddresses();
    getStates();
    getMandals();
  }, []);

  // Close notification
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification(false);
  };

  const action = (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={handleCloseNotification}
      ></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseNotification}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const isNonMobile = useMediaQuery('(min-width:600px)');

  // Attach member to new address
  async function attachAddress(addressId) {
    try {
      axios({
        method: 'PUT',
        url: `${serverURL}/member/${urlParams.memberId}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: authToken,
        },
        data: {
          address_id: addressId,
        },
      }).then((response) => {
        if (response.status === 200) {
          setNotificationMessage('New address successfully updated!');
          setNotification(true);
          setTimeout(() => {
            !debug && navigate(`/familymembers/${urlParams.memberId}`);
          }, 1500);
        }
      });
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Resource could not be found!');
      } else {
        console.log(error.message);
      }
    }
  }

  // Post new address and attach
  async function createNewAddress(data) {
    try {
      await axios({
        method: 'POST',
        url: `${serverURL}/address/`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${authToken}`,
        },
        data: {
          address_line1: data.address_line1,
          address_line2: data.address_line2,
          city: data.city,
          state_id: data.state_id,
          zipcode: data.zipcode,
        },
      }).then((response) => {
        console.log(response);
        attachAddress(response.data.id);
      });
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Resource could not be found!');
      } else {
        console.log(error.message);
      }
    }
  }

  // If add new address button is triggered post new address data then attach
  const handleNewAddressSubmit = (event) => {
    console.log(event);
    createNewAddress(event);
  };

  // Else if attach button is triggered update member address_id
  const handleExistingAddressSubmit = (event) => {
    event.preventDefault();
    const proceed = window.confirm(
      `Are you sure you want to add the new address?`
    );
    proceed && attachAddress(value.id);
  };

  const handleAddressCheck = async (event) => {
    let val = event.target.value;
    if (val.length > 4) {
      await axios({
        method: 'POST',
        url: `${serverURL}/checkAddresses/`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${authToken}`,
        },
        data: {
          address: val,
        },
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            setNotificationMessage(
              'Address already exists. Please search for the address first or ask the user if they have registered at a different mandal. Please do not put a duplicate address! If you are sure that the Apt # is different then proceed to enter the information.'
            );
            setNotification(true);
          }
          // setTimeout(() => {
          //   navigate('/members');
          // }, 1500);
        }
      });
    }
  };

  return (
    <Box m="0 20px">
      <Header title="Attach Existing Address" />
      {/* Attach existing address form */}
      <form autoComplete="off" onSubmit={handleExistingAddressSubmit}>
        <Stack spacing={2} width="500px">
          {/* <Autocomplete
          // value={addresses}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          inputValue={address}
          onInputChange={(event, newInputValue) => {
            setAddress(newInputValue);
          }}
          options={addresses}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Select an existing address"
            />
          )}
        /> */}
          <Autocomplete
            name="address_id"
            sx={{ width: 500 }}
            options={addresses}
            autoHighlight
            getOptionLabel={(option) =>
              `${option.address_line1} ${option.address_line2} ${option.city}, ${option.state.name}`
            }
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {`${option.address_line1} ${option.address_line2} ${option.city}, ${option.state.name}`}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                name="address_id"
                variant="filled"
                {...params}
                label="Choose an existing address"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Stack>
        <Box display="flex" justifyContent="end" mt="20px">
          <FormButton
            text={'Attach Address'}
            bgColor={colors.greenAccent[300]}
            textColor={colors.grey[800]}
            type={'submit'}
            onClick={handleExistingAddressSubmit}
          />
        </Box>
      </form>

      <Header title="Add New Address" />
      {/* Add new address form */}
      <Formik
        enableReinitialize={true}
        onSubmit={handleNewAddressSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
        }) => (
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <>
                <TextField
                  variant="filled"
                  type="text"
                  label="Address line 1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={capitalizeWords(values.address_line1)}
                  onKeyUp={handleAddressCheck}
                  name="address_line1"
                  error={!!touched.address_line1 && !!errors.address_line1}
                  helperText={touched.address_line1 && errors.address_line1}
                  sx={{ gridColumn: 'span 4' }}
                />
                <TextField
                  variant="filled"
                  type="text"
                  label="Address line 2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={capitalizeWords(values.address_line2)}
                  name="address_line2"
                  error={!!touched.address_line2 && !!errors.address_line2}
                  helperText={touched.address_line2 && errors.address_line2}
                  sx={{ gridColumn: 'span 4' }}
                />
                <TextField
                  variant="filled"
                  type="text"
                  label="City"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={capitalizeWords(values.city)}
                  name="city"
                  error={!!touched.city && !!errors.city}
                  helperText={touched.city && errors.city}
                  sx={{ gridColumn: 'span 1' }}
                />
                <TextField
                  select
                  variant="filled"
                  type="text"
                  label="State"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.state_id}
                  name="state_id"
                  error={!!touched.state_id && !!errors.state_id}
                  helperText={touched.state_id && errors.state_id}
                  sx={{ gridColumn: 'span 1' }}
                >
                  {states.map((state) => (
                    <MenuItem
                      key={state.id}
                      value={state.id}
                      selected={state.id == values.state_id}
                    >
                      {state.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  variant="filled"
                  type="text"
                  label="Zip / Postal Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.zipcode}
                  name="zipcode"
                  error={!!touched.zipcode && !!errors.zipcode}
                  helperText={touched.zipcode && errors.zipcode}
                  sx={{ gridColumn: 'span 1' }}
                />
                <TextField
                  select
                  variant="filled"
                  type="text"
                  label="Mandal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mandal_id}
                  name="mandal_id"
                  error={!!touched.mandal_id && !!errors.mandal_id}
                  helperText={touched.mandal_id && errors.mandal_id}
                  sx={{ gridColumn: 'span 1' }}
                >
                  {mandals.map((mandal) => (
                    <MenuItem key={mandal.id} value={mandal.id}>
                      {mandal.name}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <FormButton
                text={'Add New Address'}
                bgColor={colors.greenAccent[300]}
                textColor={colors.grey[800]}
                type={'submit'}
              />
            </Box>
            {debug && (
              <>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Touched</strong>
                  <br />
                  {JSON.stringify(touched, null, 2)}
                </pre>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        // autoHideDuration={7500}
        onClose={handleCloseNotification}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{notificationMessage}</span>}
        action={action}
      />
    </Box>
  );
}

export default AttachAddress;
