import { Box, useTheme } from '@mui/material';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { tokens } from '../../theme';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { serverURL, authToken, config } from '../../authentication/config';
import AddressForm from '../../components/Forms/Address/AddressForm';
import FormButton from '../../components/Forms/FormButton';

const debug = false;

const validationSchema = Yup.object().shape({
  address_line1: Yup.string().required('Address is required'),
  address_line2: Yup.string().nullable(),
  city: Yup.string().required('City is required'),
  state_id: Yup.number()
    .required('State is required')
    .min(1, 'Please select a State'),
  zipcode: Yup.string().required('Zip / Postal Code is required'),
  mandal_id: Yup.number()
    .required('Mandal is required')
    .min(1, 'Please select a Mandal'),
  notes: Yup.string().nullable(),
});

const initialValues = {
  address_line1: '',
  address_line2: '',
  city: '',
  state_id: '',
  zipcode: '',
  mandal_id: '',
  notes: '',
};

function EditAddress() {
  // Set page theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const urlParams = useParams();

  const [mandals, setMandals] = useState([{ id: 0, name: 'Select Mandal' }]);
  const [states, setStates] = useState([]);
  const [address, setAddress] = useState(initialValues);
  const [notification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  useEffect(() => {
    // Set page title
    document.title = 'Edit Address | Shree Swaminarayan Gadi Parivar';

    // // Fetch country from server
    // async function getAddress() {
    //   try {
    //     const results = await axios(
    //       `${serverURL}/address/${urlParams.uuid}`,
    //       config
    //     );
    //     setAddress({
    //       address_line1: results.data.address_line1,
    //       address_line2: results.data.address_line2,
    //       city: results.data.city,
    //       state_id: results.data.state_id,
    //       zipcode: results.data.zipcode,
    //     });
    //   } catch (error) {
    //     if (error.response.status === 404) {
    //       console.log('Resource could not be found!');
    //     } else {
    //       console.log(error.message);
    //     }
    //   }
    // }

    // Fetch member from server
    async function getMember() {
      try {
        const results = await axios(
          `${serverURL}/member/${urlParams.memberId}/address`,
          config
        );
        setAddress({
          address_line1: results.data.address.address_line1,
          address_line2: results.data.address.address_line2,
          city: results.data.address.city,
          state_id: results.data.address.state_id,
          zipcode: results.data.address.zipcode,
          mandal_id: results.data.mandal_id,
          notes: results.data.address.notes,
        });
      } catch (error) {
        if (error.response.status === 404) {
          console.log('Resource could not be found!');
        } else {
          console.log(error.message);
        }
      }
    }

    // Fetch states from server
    async function getStates() {
      try {
        const results = await axios(
          `${serverURL}/northamerica/states/`,
          config
        );
        setStates(results.data);
      } catch (error) {
        if (error.response.status === 404) {
          console.log('Resource could not be found!');
        } else {
          console.log(error.message);
        }
      }
    }

    // Fetch country from server
    async function getMandals() {
      try {
        const results = await axios(`${serverURL}/mandallist/`, config);
        setMandals(results.data);
      } catch (error) {
        if (error.response.status === 404) {
          console.log('Resource could not be found!');
        } else {
          console.log(error.message);
        }
      }
    }

    getMember();
    getStates();
    getMandals();
  }, []);

  // Close notification
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification(false);
  };

  const isNonMobile = useMediaQuery('(min-width:600px)');

  // Post form data
  async function postData(data) {
    console.log(data);
    try {
      await axios({
        method: 'PUT',
        url: `${serverURL}/address/${urlParams.uuid}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: authToken,
        },
        data: data,
      }).then((response) => {
        setNotificationMessage('Successfully updated address!');
        setNotification(true);
        setTimeout(() => {
          !debug && navigate(`/familymembers/${urlParams.memberId}`);
        }, 1500);
      });
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Resource could not be found!');
      } else {
        console.log(error.message);
      }
    }
  }

  const handleFormSubmit = (event) => {
    postData(event);
  };

  return (
    <Box m="0 20px">
      <Header title="Edit Address" />

      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={address}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
        }) => (
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <AddressForm
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                values={values}
                touched={touched}
                states={states}
                mandals={mandals}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <FormButton
                text={'Submit'}
                bgColor={colors.greenAccent[300]}
                textColor={colors.grey[800]}
                type={'submit'}
              />
            </Box>
            {debug && (
              <>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Touched</strong>
                  <br />
                  {JSON.stringify(touched, null, 2)}
                </pre>
                <pre style={{ textAlign: 'left' }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{notificationMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseNotification}
          ></IconButton>,
        ]}
      />
    </Box>
  );
}

export default EditAddress;
