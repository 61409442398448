import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import capitalizeWords from '../../capitalizeWord';
import { MuiTelInput } from 'mui-tel-input';
import moment from 'moment';
import { useState } from 'react';

const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

function MemberForm({
  handleBlur,
  handleChange,
  errors,
  values,
  touched,
  setFieldValue,
}) {
  const [value, setValue] = useState();
  const onChangeHandler = (ev) => {
    setValue(ev);
    setFieldValue('mobile_phone', ev);
  };
  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const birthYears = range(currentYear, currentYear - 100, -1);
  return (
    <>
      <TextField
        variant="filled"
        name={`first_name`}
        label="First Name"
        value={capitalizeWords(values.first_name)}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!touched.first_name && !!errors.first_name}
        helperText={touched.first_name && errors.first_name}
        sx={{ gridColumn: 'span 1' }}
      />

      <TextField
        variant="filled"
        name={`middle_name`}
        label="Middle Name"
        value={capitalizeWords(values.middle_name)}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!touched.middle_name && !!errors.middle_name}
        helperText={touched.middle_name && errors.middle_name}
        sx={{ gridColumn: 'span 1' }}
      />
      <TextField
        variant="filled"
        name={`last_name`}
        label="Last Name"
        value={capitalizeWords(values.last_name)}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!touched.last_name && !!errors.last_name}
        helperText={touched.last_name && errors.last_name}
        sx={{ gridColumn: 'span 1' }}
      />
      <TextField
        variant="filled"
        name={`ghaam`}
        label="Ghaam"
        value={capitalizeWords(values.ghaam)}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!touched.ghaam && !!errors.ghaam}
        helperText={touched.ghaam && errors.ghaam}
        sx={{ gridColumn: 'span 1' }}
      />
      <TextField
        variant="filled"
        name={`email`}
        label="Email"
        value={values.email.toLowerCase()}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!touched.email && !!errors.email}
        helperText={touched.email && errors.email}
        sx={{ gridColumn: 'span 1' }}
      />
      <TextField
        variant="filled"
        type="date"
        label="Date of Birth"
        onBlur={handleBlur}
        onChange={handleChange}
        value={
          values.dob === null
            ? moment(Date.now()).format('YYYY-MM-DD')
            : values.dob
        }
        name={`dob`}
        error={!!touched.dob && !!errors.dob}
        helperText={touched.dob && errors.dob}
        sx={{ gridColumn: 'span 1' }}
      />
      <TextField
        select
        variant="filled"
        type="text"
        label="Birth Year"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.birth_year}
        name={`birth_year`}
        error={!!touched.birth_year && !!errors.birth_year}
        helperText={touched.birth_year && errors.birth_year}
        sx={{ gridColumn: 'span 1' }}
      >
        {birthYears.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </TextField>
      <MuiTelInput
        variant="filled"
        name={`mobile_phone`}
        label="Mobile Phone"
        value={values.mobile_phone}
        onBlur={handleBlur}
        onChange={onChangeHandler}
        error={!!touched.mobile_phone && !!errors.mobile_phone}
        helperText={touched.mobile_phone && errors.mobile_phone}
        sx={{ gridColumn: 'span 1' }}
      />
      {/* <TextField
        variant="filled"
        name={`home_phone`}
        label="Home Phone"
        value={values.home_phone}
        onBlur={handleBlur}
        onChange={handleChange}
        error={!!touched.home_phone && !!errors.home_phone}
        helperText={touched.home_phone && errors.home_phone}
        sx={{ gridColumn: 'span 1' }}
      /> */}

      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          error={!!touched.gender && !!errors.gender}
        >
          Gender
        </FormLabel>
        <RadioGroup
          row
          aria-label="position"
          name={`gender`}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.gender}
          sx={{ gridColumn: 'span 1' }}
        >
          {genderOptions.map((option) => (
            <FormControlLabel
              variant="filled"
              type="radio"
              value={option.value}
              key={option.value}
              checked={values.gender === option.value}
              label={option.label}
              id="gender"
              control={
                <Radio
                  sx={{
                    '&.Mui-checked': {
                      color: 'rgba(255, 255, 255, 0.7);',
                    },
                  }}
                />
              }
            />
          ))}
        </RadioGroup>
        {touched.gender && Boolean(errors.gender) ? (
          <Box
            className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1ksiht6-MuiFormHelperText-root"
            severity="error"
          >
            Please choose a gender
          </Box>
        ) : (
          ''
        )}
      </FormControl>
      <FormControl>
        <FormLabel error={!!touched.satsangi && !!errors.satsangi}>
          Is Satsangi
        </FormLabel>
        <FormControlLabel
          control={<Checkbox checked={values.satsangi} />}
          label="Yes"
          name={`satsangi`}
          onChange={handleChange}
          sx={{
            gridColumn: 'span 1',
            '& .MuiSvgIcon-root': {
              fill: 'rgba(255, 255, 255, 0.7);',
            },
          }}
        />
        {touched.satsangi && Boolean(errors.satsangi) ? (
          <Box
            className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1ksiht6-MuiFormHelperText-root"
            severity="error"
          >
            ('')
          </Box>
        ) : (
          ''
        )}
      </FormControl>
      <FormControl>
        <FormLabel
          error={!!touched.sabhya && !!errors.sabhya}
          // helperText={touched.Sabhya && errorSabhya}
        >
          Is Sabhya
        </FormLabel>
        <FormControlLabel
          control={<Checkbox checked={values.sabhya} />}
          label="Yes"
          name={`sabhya`}
          onChange={handleChange}
          sx={{
            gridColumn: 'span 1',
            '& .MuiSvgIcon-root': {
              fill: 'rgba(255, 255, 255, 0.7);',
            },
          }}
        />
        {touched.sabhya && Boolean(errors.sabhya) ? (
          <Box
            className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1ksiht6-MuiFormHelperText-root"
            severity="error"
          >
            ('')
          </Box>
        ) : (
          ''
        )}
      </FormControl>
      <FormControl>
        <FormLabel
          error={!!touched.head_of_household && !!errors.head_of_household}
          // helperText={touched.HOH && errorHOH}
        >
          Is Head of Household
        </FormLabel>
        <FormControlLabel
          control={<Checkbox checked={values.head_of_household} />}
          label="Yes"
          name={`head_of_household`}
          onChange={handleChange}
          sx={{
            gridColumn: 'span 1',
            '& .MuiSvgIcon-root': {
              fill: 'rgba(255, 255, 255, 0.7);',
            },
          }}
        />
        {touched.head_of_household && Boolean(errors.head_of_household) ? (
          <Box
            className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1ksiht6-MuiFormHelperText-root"
            severity="error"
          >
            ('')
          </Box>
        ) : (
          ''
        )}
      </FormControl>
      <FormControl>
        <TextField
          variant="filled"
          name={`notes`}
          label="Notes"
          value={values.notes}
          onBlur={handleBlur}
          onChange={handleChange}
          fullWidth
          multiline // Enable multi-line input
          rows={6} // Specify the number of rows displayed
          error={!!touched.notes && !!errors.notes}
          helperText={touched.notes && errors.notes}
          sx={{ gridColumn: 'span 1' }}
        />
      </FormControl>
    </>
  );
}

export default MemberForm;
