import { useState } from 'react';
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Routes, Route } from 'react-router-dom';
import TopBar from './pages/global/Topbar';
import SideBar from './pages/global/SideBar';
import Dashboard from './pages/Dashboard';
import AddMember from './pages/Form/AddMember';
import Members from './pages/Members';
import FamilyMembers from './pages/FamilyMembers';
import AddFamily from './pages/Form/AddFamily';
import EditAddress from './pages/Form/EditAddress';
import EditMember from './pages/Form/EditMember';
import AttachAddress from './pages/Form/AttachAddress';
import Login from './pages/Login';
// import RequireAuth from './components/RequireAuth';

function App() {
  // const [isLogged, setIsLogged] = useState(false);
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {/* <ProSidebarProvider>
            <SideBar />
          </ProSidebarProvider> */}
          <main className="content">
            <TopBar />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/members" element={<Members />} />
              <Route path="/" element={<Login />} />
              <Route path="/familymembers/:uuid" element={<FamilyMembers />} />
              <Route
                path="/familymembers/add/:countryId"
                element={<AddFamily />}
              />
              <Route
                path="/member/add/:addressId/:memberId/:mandalId"
                element={<AddMember />}
              />
              <Route path="/member/edit/:uuid" element={<EditMember />} />
              <Route
                path="/address/edit/:uuid/:memberId"
                element={<EditAddress />}
              />
              <Route
                path="/address/attach/:memberId"
                element={<AttachAddress />}
              />
              {/* <Route element={<RequireAuth logged={isLogged} />}></Route> */}
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
